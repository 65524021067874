import styles from "./BotInfo.module.scss"
import {CONTACTS} from "../../../../../utils/constants"
import {animated, useTransition} from "@react-spring/web"
import Button from "../../Button";

export default function BotInfo({isBlockVisible}) {
	const transition = useTransition(isBlockVisible, {
		from: {
			y: -125,
		},
		enter: {
			y: 0,
		},
		leave: {
			y: -125,
		},
		config: {duration: 150},
	})

	return transition((style, isBlockVisible) => (isBlockVisible ? <Content style={style} /> : null))
}

const Info = (props) => {
	const phone = JSON.parse(localStorage.getItem("gtm-phone")) || CONTACTS.phone.title
	const phoneHref = `tel:+${phone.replace(/\D/g, "")}`
	return (
		<div className={styles.fixedBotInfo} {...props}>
			<div className={styles.botInfo}>
				<div className={styles.image}>
					<img src='/images/bot.png' alt='' width={100} height={100} />
				</div>
				<div className={styles.botPost}>
					Онлайн-консультант <br /> автосалона OMODA МАКСИМУМ
				</div>
			</div>
			<Button onClick={() => window.location.assign(phoneHref)} type={"bot"}>
				<span>Позвонить</span>
				<span className={styles.phone}>: {phone}</span>
			</Button>
		</div>
	)
}

const Content = animated(Info)
