export default function apiRequest(model = "", phone, message, name = "Ввод не предусмотрен") {
	const data = {
		name: "Ввод не предусмотрен",
		phone,
		message,
		model: model,
		target: message,
		url: window.location.href,
	}

	fetch("https://maximum-auto.ru/api/leadcollector/", {
		method: "POST",
		body: JSON.stringify({
			brand: "omoda-transportnaya",
			communication_type: "site",
			date_time: new Date(),
			dep_type: "ОПНА",
			name,
			phone_number: phone,
			message,
			model: `OMODA ${model}`,
		}),
	})
		.then(() => {
			console.info("Заявка отправлена в ЛК")
		})
		.catch(console.error)

	const Comagic = window.Comagic
	try {
		Comagic.addOfflineRequest(data)
	} catch (error) {
		console.warn(`Отправка в Comagic не удалась. Причина ${error}`)
	}
}
