import styles from "./ChatMessage.module.scss"
import {animated, easings, useSpring} from "@react-spring/web"

export default function ChatMessage({children, first, user}) {
	const springs = useSpring({
		from: {x: user ? 40 : -40, opacity: 0},
		to: {x: 0, opacity: 1},
		config: {
			duration: 500,
			easing: easings.easeOutCubic,
		},
	})

	let classNames = [styles.root, first ? styles.first : "", user ? styles.user : ""].join(" ")

	return (
		<>
			<animated.div style={{...springs}} className={classNames}>
				{children}
			</animated.div>
		</>
	)
}
