import styles from "./CreditFeatures.module.scss"

export default function CreditFeatures() {
	return (
		<div className={styles.root}>
			<ul className={styles.list}>
				<li className={styles.item}>
					<div className={styles.row}>
						до
						<span className={styles.number}>99</span>%
					</div>
					<div className={styles.row}>заявок одобрено</div>
				</li>
				<li className={styles.divider} />
				<li className={styles.item}>
					<div className={styles.row}>
						от
						<span className={styles.number}>0</span>%
					</div>
					<div className={styles.row}>первый взнос</div>
				</li>
				<li className={styles.divider} />
				<li className={styles.item}>
					<div className={styles.row}>
						<span className={styles.number}>15</span>+
					</div>
					<div className={styles.row}>банков-партнеров</div>
				</li>
			</ul>
		</div>
	)
}
