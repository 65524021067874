import Container from "../Container"
import Model from "../Model/Model"
import {models} from "../../configs/configModels"
import styles from "./ModelsList.module.scss"

export default function ModelsList() {
	return (
		<section className={styles.root}>
			<Container>
				{models.map((model) => (
					<Model key={model.name} data={model} />
				))}
			</Container>
		</section>
	)
}
