import styles from "./Features.module.scss"

export default function Features() {
	return (
		<div className={styles.root}>
			{/* <div className={styles.title}>Эксклюзивные условия:</div> */}
			<ul className={styles.list}>
				<li className={styles.item}>
					<div className={styles.number}>7</div>
					<div className={`${styles.text}`}>лет</div>
					<div className={`${styles.secondRow} ${styles.text}`}>гарантия</div>
				</li>
				<li className={styles.divider} />
				<li className={styles.item}>
					<div className={styles.number}>0</div>
					<div className={`${styles.text}`}>₽</div>
					<div className={`${styles.secondRow} ${styles.text}`}>каско</div>
				</li>
				<li className={styles.divider} />
				<li className={styles.item}>
					<div className={styles.number}>0</div>
					<div className={`${styles.text}`}>%</div>
					<div className={`${styles.secondRow} ${styles.text}`}>рассрочка</div>
				</li>
			</ul>
		</div>
	)
}
