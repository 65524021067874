import ChatIcon from "../ChatIcon/ChatIcon"
import styles from "./ChatModelCard.module.scss"
import {animated, easings, useSpring} from "@react-spring/web"

export default function ChatModelCard({model, activeModel, chooseModel}) {
	const {name, benefit, id} = model

	const springs = useSpring({
		from: {x: -40, opacity: 0},
		to: {x: 0, opacity: 1},
		config: {
			duration: 500,
			easing: easings.easeOutCubic,
		},
	})

	return (
		<>
			<animated.div style={{...springs}} key={model.name} className={styles.card}>
				<div className={`${styles.wrapper} ${activeModel.name === name ? styles.active : ''}`} onClick={() => chooseModel(model)}>
					<img src={`/images/chat-models/${id}.png`} className={styles.image} alt='Изображение автомобиля' width={210} height={108} />
					<div className={styles.content}>
						<p className={styles.title}>{name}</p>
						{benefit && <p className={styles.benefit}>Выгода до {benefit} ₽</p>}
					</div>
				</div>
				{activeModel.name === name && (
					<div className={styles.icon}>
						<ChatIcon.Check />
					</div>
				)}
			</animated.div>
		</>
	)
}
