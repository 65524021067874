import {useContext, useEffect, useState} from "react"
import InputMask from "react-input-mask"
import apiRequest from "../../utils/request"
import Button from "../Button/Button"
import {ModalContext} from "../../contexts/ModalContext"
import styles from "./Modal.module.scss"
import Icon from "../Icon"

export default function Modal() {
	const {modal, modalTitle, modalMessage, handleModal, model} = useContext(ModalContext)
	const [value, setValue] = useState("")
	const [isSended, setIsSended] = useState(false)
	const [error, setError] = useState(false)

	function resetForm(e) {
		setError(false)
		setValue("")
		setIsSended(true)
		e.target.reset()
	}

	function onChange(e) {
		if (error) setError(false)
		setValue(e.target.value)
	}

	useEffect(() => {
		if (isSended) {
			setIsSended(false)
		}
	}, [modal])

	function onSubmit(e) {
		e.preventDefault()
		if (value.replace(/\D/g, "").length < 11) {
			setError(true)
			return
		}
		apiRequest(model, value, modalMessage)
		resetForm(e)
		setTimeout(() => {
			handleModal()
		}, 2000)
	}

	return (
		<div className={`${styles.modal} ${modal ? styles.active : ""}`}>
			<div className={`${styles.callback}`} id='callbackModal'>
				<div className={styles.close} onClick={() => handleModal()}>
					<Icon.Close />
				</div>
				<div className={styles.image}>
					<img src='/images/modal-bg.jpg' alt='' width={552} height={280} />
				</div>
				<form onSubmit={onSubmit} id='modalForm' className={styles.callback__form}>
					{!isSended && (
						<>
							<div className={styles.title}>{modalTitle}</div>
							<InputMask mask='+7 (999) 999-99-99' value={value} onChange={onChange}>
								{(inputProps) => (
									<input
										{...inputProps}
										type='tel'
										required
										minLength={18}
										placeholder='+7 (___) ___-__-__'
										className={`${styles.callback__input} ${error ? styles.error : ""}`}
									/>
								)}
							</InputMask>
							<div className={styles.buttonWrap}>
								<Button fullwidth type={"primary"}>
									Отправить
								</Button>
							</div>
							<div className={styles.policy}>
								*Отправляя заявку, я соглашаюсь на обработку&nbsp;
								<a className={styles.link} href='/privacy-policy' target='_blank'>
									персональных данных
								</a>
							</div>
						</>
					)}
					{isSended && (
						<div className='cb-load-text'>
							<div className={styles.title}>Заявка отправлена</div>
							<div className={styles.callback__comment}>Ожидайте, мы перезвоним вам в ближайшее время</div>
						</div>
					)}
				</form>
			</div>
		</div>
	)
}
