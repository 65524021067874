import styles from "./CallToActionForm.module.scss"
import Container from "../Container"
import InputMask from "react-input-mask"
import Button from "../Button/Button"
import apiRequest from "../../utils/request"
import {useState} from "react"

export default function CallToActionForm({title, description, name}) {
	const [value, setValue] = useState("")
	const [isSended, setIsSended] = useState(false)
	const [error, setError] = useState(false)

	function resetForm(e) {
		setError(false)
		setValue("")
		setIsSended(true)
		e.target.reset()
	}

	function onChange(e) {
		if (error) setError(false)
		setValue(e.target.value)
	}

	function onSubmit(e) {
		e.preventDefault()
		if (value.replace(/\D/g, "").length < 11) {
			setError(true)
			return
		}
		apiRequest("Omoda", value, `Заявка из формы ${name}`)
		resetForm(e)
	}

	return (
		<div className={styles.root} id={name}>
			<div className={styles.background}>
				<picture>
					<source srcSet={`/images/${name}.jpg`} media={"(min-width: 768px)"} width={1920} height={524} />
					<img loading={"lazy"} src={`/images/${name}-m.jpg`} alt='' width={437} height={368} />
				</picture>
			</div>
			<Container>
				<div className={styles.wrapper}>
					<div className={styles.title}>{title}</div>
					<div className={styles.description}>{description}</div>
					<form onSubmit={onSubmit} id='modalForm' className={styles.form}>
						<div className={styles.label}>Ваш телефон:</div>
						<div className={styles.formWrap}>
							<InputMask mask='+7 (999) 999-99-99' value={value} onChange={onChange}>
								{(inputProps) => (
									<input
										{...inputProps}
										type='tel'
										required
										minLength={18}
										placeholder='+7 (___) ___-__-__'
										className={`${styles.input} ${error ? "error" : ""}`}
									/>
								)}
							</InputMask>
							<div className={styles.buttonWrap}>
								<Button disabled={isSended} type={"primary"} fullwidth>
									{isSended ? "Заявка отправлена" : "Отправить"}
								</Button>
							</div>
						</div>
						<div className={styles.policy}>
							*Отправляя заявку, я соглашаюсь на обработку&nbsp;
							<a className={styles.link} href='/privacy-policy' target='_blank'>
								персональных данных
							</a>
						</div>
					</form>
				</div>
			</Container>
		</div>
	)
}
