import {useEffect, useState} from "react"

export function useTopPadding(id) {
	const [isBlockVisible, setIsBlockVisible] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			// Получаем координаты блока
			const block = document.getElementById(id) // Замените 'yourBlockId' на реальный id вашего блока

			const blockRect = block?.getBoundingClientRect()

			// Проверяем, достиг ли блок верхушки экрана
			const isVisible = blockRect?.top <= 0

			// Устанавливаем состояние видимости блока
			setIsBlockVisible(isVisible)
		}

		// Добавляем слушатель события прокрутки
		window.addEventListener("scroll", handleScroll)

		// Убираем слушатель при размонтировании компонента
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, []) //y array ensures that effect is only run on mount
	return isBlockVisible
}
