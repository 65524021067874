import styles from "./Button.module.scss"
import Icon from "../Icon"

export default function Button({type = "black", size = "medium", color = "", active, children, fullwidth, icon, iconLeft, onClick, disabled = false}) {
	return (
		<button
			style={fullwidth ? {width: "100%"} : null}
			className={`${styles.button} ${styles[type]} ${styles[color] || ""} ${styles[size] || ""}`}
			disabled={disabled}
			onClick={onClick}
		>
			{iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
			{children}
			{icon && <div className={styles.icon}>{icon}</div>}
			{type === "param" && <Icon.Cross />}
		</button>
	)
}
