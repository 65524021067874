import {useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import InputMask from "react-input-mask"

import Button from "../Button"
import styles from "./ChatModal.module.scss"
import {ModalContext} from "../../../../contexts/ModalContext"
import apiRequest from "../../../../utils/request"
import ChatMessage from "../ChatMessage/ChatMessage"
import ChatIcon from "../ChatIcon/ChatIcon"
import ChatPopup from "../ChatPopup/ChatPopup"

export default function ChatModal() {
	const {modalTitle, modalMessage, handleModal, modal, modalDepType} = useContext(ModalContext)
	const [value, setValue] = useState("")
	const [isSended, setIsSended] = useState(false)
	const [error, setError] = useState(false)

	function resetForm(e) {
		setError(false)
		setValue("")
		setIsSended(true)
		e.target.reset()
	}

	function onChange(e) {
		if (error) setError(false)
		setValue(e.target.value)
	}

	useEffect(() => {
		if (isSended) {
			setIsSended(false)
		}
	}, [modal])

	function onSubmit(e) {
		e.preventDefault()
		if (value.replace(/\D/g, "").length < 11) {
			setError(true)
			return
		}
		apiRequest("Omoda", value, `${modalMessage} OMODA`)
		resetForm(e)
		// setTimeout(() => {
		// 	handleModal()
		// }, 2000)
	}

	function handleClick(e) {
		if (e.target.id === "callbackModal") {
			handleModal()
		}
	}

	return (
		<div className={styles.modal}>
			<div className={`${styles.callback} ${modal ? styles.callbackActive : ""}`} id='callbackModal' onClick={handleClick}>
				{!isSended && (
					<form onSubmit={onSubmit} id='modalForm' className={styles.form}>
						<div className={styles.formHeader}>
							<div className={styles.image}>
								<img src='/images/bot.png' alt='' width={100} height={100} />
							</div>
							<div className={styles.text}>
								<p className={styles.job}>
									Онлайн-консультант <br /> автосалона OMODA МАКСИМУМ
								</p>
							</div>
						</div>

						<div className={styles.formMain}>
							<ChatMessage first>
								<div className={styles.title} dangerouslySetInnerHTML={{__html: modalTitle}}></div>
							</ChatMessage>
							<div className={styles.inputWrapper}>
								<InputMask mask='+7 (999) 999-99-99' value={value} onChange={onChange}>
									{(inputProps) => (
										<input
											{...inputProps}
											type='tel'
											required
											minLength={18}
											placeholder='+7 (___) ___-__-__'
											className={`${styles.input} ${error ? styles.error : ""}`}
										/>
									)}
								</InputMask>
							</div>
							<div className={styles.buttonWrap}>
								<Button size={"medium"} type={"chat"}>
									Отправить
								</Button>
							</div>
							<div className={styles.policy}>
								<div>
									Нажимая кнопку “Отправить”, я соглашаюсь на обработку&nbsp;
									<Link className={styles.policyLink} to='/privacy-policy' target='_blank'>
										персональных данных
									</Link>
								</div>
							</div>
						</div>

						<div className={styles.close} onClick={() => handleModal()}>
							<ChatIcon.Close />
						</div>
					</form>
				)}
				<div className={`${styles.finalPopup} ${isSended ? styles.finalPopupActive : ""}`}>
					<ChatPopup title={"Спасибо!"} subtitle={"Ваша заявка принята <br /> Мы скоро свяжемся с вами"} isInForm />
					<div className={styles.close} onClick={() => handleModal()}>
						<ChatIcon.Close />
					</div>
				</div>
			</div>
		</div>
	)
}
