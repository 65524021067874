import styles from "./ChatBenefit.module.scss"
import {useContext} from "react"
import {ModalContext} from "../../../../../contexts/ModalContext"

export default function ChatBenefit() {
	const {handleModal} = useContext(ModalContext)
	return (
		<div
			className={styles.benefit}
			onClick={() =>
				handleModal("Здравствуйте!<br>Напишите свой номер, и я подробно расскажу обо всех доступных преимуществах от OMODA и нашего салона.")
			}
		>
			Комплект резины + КАСКО в подарок. <span>Подробнее</span>
		</div>
	)
}
