import styles from "./ArticleHeader.module.scss"
import Container from "../../Container";
import Logo from "../../Logo/Logo";
import Icon from "../../Icon";

export default function ArticleHeader() {
	return (
		<header className={styles.root}>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<Logo color={"#202020"} />
					<a className={styles.phone} href='tel:+78126038368'>
						<Icon.Phone />
						<span>+7 (812) 603-83-68</span>
					</a>
				</div>
			</Container>
		</header>
	)
}
