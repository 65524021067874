import {capitalize} from "lodash"

export const decimalFormatter = (num) => {
	var n = num.toString()
	var separator = "\xa0"
	return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator)
}

export const carRandomizer = (carAmount, cars) => {
	let randomizedCars = []

	for (let i = 0; i < carAmount; i++) {
		const randNumber = Math.floor(Math.random() * (cars.length - 0 + 1))
		randomizedCars.push(cars[randNumber])
	}
	return randomizedCars
}

export const generateCarImgPath = (car) => {
	return `/img/pics/models/${car.modelName.split(" ").join("")}/side/${capitalize(car.Color)}.png`
}

export const fillArray = (n) => {
	let arr = []
	if (n) for (let i = 0; i <= n; ) arr.push(i++)
	return arr
}

export const capitalizeAfterSpace = (str) => {
	return str.replace(/(^|\s)(\w)/g, function (match) {
		return match.toUpperCase()
	})
}
